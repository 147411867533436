<template>
    <v-app-bar color="white" app dense clipped-right :clipped-left="!$route.params.sid" height="48px" flat class="toolbar-border-bottom">
        <div v-if="!$route.params.sid" class="d-flex align-center">
            <v-toolbar-title class="pl-0 flex-shrink-0">
                <router-link v-if="$vuetify.breakpoint.smAndUp" :to="{ name: 'welcome' }" style="text-decoration: none">
                    <a class="secondary--text ma-0 pa-0" style="min-width: 200px">
                        <v-hover v-slot:default="{ hover }">
                            <div class="d-flex align-center ml-1">
                                <v-img
                                    class="ml-2 mr-1"
                                    :style="hover ? 'filter: grayscale(100%)' : ''"
                                    contain
                                    max-width="110px"
                                    max-height="23px"
                                    src="@/assets/Nuvolos-transparent.svg" />
                            </div>
                        </v-hover>
                    </a>
                </router-link>
            </v-toolbar-title>
        </div>
        <template v-if="$route.params.oid">
            <v-divider v-if="!$route.params.sid" class="ml-3" inset vertical></v-divider>
            <BreadcrumbsLargeScreen v-if="$vuetify.breakpoint.mdAndUp" />
            <BreadcrumbsSmallScreen v-else />
        </template>
        <v-spacer />
        <v-tooltip bottom v-if="displayAPIWarning">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                    <v-icon color="red">mdi-alert</v-icon>
                </v-btn>
            </template>
            <span>You are connected to {{ customBaseURL }}</span>
        </v-tooltip>
        <div v-if="$vuetify.breakpoint.mdAndUp && $auth.isAuthenticated" class="d-flex align-center justify-space-around flex-shrink-0">
            <template v-if="userInfo && userInfo.is_trial_user">
                <v-chip label>
                    {{ trialText }}
                    <v-btn
                        v-if="$appConfig.VUE_APP_INTERCOM_ID"
                        elevation="0"
                        x-small
                        class="ml-5"
                        color="primary"
                        @click="showNewMessageIntercom(trialMsgIntercom)">
                        {{ trialButtonLabel }}
                    </v-btn>
                </v-chip>
            </template>
            <v-menu v-if="userInfo && Object.keys(userInfo).length" :close-on-content-click="true" :nudge-width="150" offset-y left class="mt-1">
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon rounded v-on="{ ...tooltip, ...menu }" text e2e-toolbar-running-apps>
                                <v-badge v-if="runningDeployments.length" color="primary" right overlap>
                                    <template v-slot:badge>
                                        <v-fab-transition>
                                            <span v-text="runningDeployments.length" fab></span>
                                        </v-fab-transition>
                                    </template>
                                    <v-icon color="secondary">desktop_windows</v-icon>
                                </v-badge>
                                <v-icon v-else color="secondary">desktop_windows</v-icon>
                            </v-btn>
                        </template>
                        <span>Running Apps</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text subtitle-1 mt-3 font-weight-bold">Running Applications</v-list-item-title>
                            <v-list-item-subtitle>{{ runningDeployments.length }} applications found</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="runningDeployments.length" class="mt-3 mb-3"></v-divider>
                    <v-list-item-group color="secondary">
                        <v-tooltip bottom v-for="app in runningDeployments" :key="app.aid">
                            <template v-slot:activator="{ on }">
                                <v-list-item
                                    v-on="on"
                                    :to="{ name: 'app-open', params: { oid: app.oid, sid: app.sid, iid: app.iid, snid: app.snid, aid: app.aid } }">
                                    <v-list-item-avatar>
                                        <img :src="appIcon(app)" />
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="d-flex align-center">
                                            <h4 class="secondary--text mr-2">{{ app.name }}</h4>
                                            <v-chip v-if="app.shared === 'true'" small outlined>shared</v-chip>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <stop-application :app="app" :appName="app.name" :isShared="app.shared === 'true'" :sessionId="app.session_id">
                                            <v-btn color="secondary" icon>
                                                <v-icon dark>stop</v-icon>
                                            </v-btn>
                                        </stop-application>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            <div class="d-flex flex-column caption">
                                <span>
                                    Space:
                                    <span class="font-weight-bold text-uppercase">{{ app.space_long_id }}</span>
                                </span>
                                <span>
                                    Instance:
                                    <span class="font-weight-bold text-uppercase">{{ app.instance_long_id }}</span>
                                </span>
                            </div>
                        </v-tooltip>
                    </v-list-item-group>
                </v-list>

                <v-divider></v-divider>
            </v-menu>
            <v-menu :close-on-content-click="true" :nudge-width="150" offset-y left class="mt-1">
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip, ...menu }" rounded icon e2e-toolbar-notifications>
                                <v-badge v-if="(!runningTasksError && runningTasks > 0) || validRequests.length" color="primary" right dot overlap>
                                    <v-icon color="secondary">notifications</v-icon>
                                </v-badge>
                                <v-icon v-else color="secondary">notifications</v-icon>
                            </v-btn>
                        </template>
                        <span>User Notifications</span>
                    </v-tooltip>
                </template>
                <v-list nav dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text subtitle-1 mt-3 font-weight-bold">User Notifications</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-3"></v-divider>
                    <v-list-item @click="openTasks()">
                        <v-list-item-title class="subtitle-2 secondary--text">
                            Tasks ({{ runningTasksError ? `error` : `${runningTasks} running` }})
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openRequests()">
                        <v-list-item-title class="subtitle-2 secondary--text">Requests ({{ validRequests.length }} pending)</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y left>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn rounded color="secondary" icon v-on="{ ...tooltip, ...menu }" e2e-toolbar-help>
                                <v-icon>mdi-lifebuoy</v-icon>
                            </v-btn>
                        </template>
                        <span>Help</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item @click="openDocs()">
                        <v-list-item-action>
                            <v-icon small>help_outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Documentation</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$appConfig.VUE_APP_INTERCOM_ID" @click="showIntercomChat">
                        <v-list-item-action>
                            <v-icon small>mdi-lifebuoy</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Ask for help</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu max-width="200" offset-y>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn rounded icon color="secondary" v-on="{ ...tooltip, ...menu }" e2e-toolbar-account>
                                <v-icon>account_circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Account</span>
                    </v-tooltip>
                </template>
                <v-list nav dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="secondary--text subtitle-1 mt-3 font-weight-bold">Account & Settings</v-list-item-title>
                            <v-list-item-subtitle>{{ userInfo ? userInfo.given_name : '' }} {{ userInfo ? userInfo.family_name : '' }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-3"></v-divider>
                    <v-list-item @click="openSettings()">
                        <v-list-item-title class="subtitle-2 secondary--text" e2e-toolbar-settings>User Settings</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openResourceAndUsage()" v-if="isAccountManager">
                        <v-list-item-title class="subtitle-2 secondary--text" e2e-toolbar-account-management>Account Management</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openMonitoring()">
                        <v-list-item-title class="subtitle-2 secondary--text" e2e-toolbar-monitoring>Resources</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="userInfo && userInfo.is_sysadmin" @click="openSysadmin()">
                        <v-list-item-title class="subtitle-2 secondary--text" e2e-toolbar-sysadmin>Sysadmin</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-title class="subtitle-2 secondary--text" e2e-toolbar-logout>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div v-else-if="$auth.isAuthenticated">
            <v-menu offset-y offset-overflow :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon>menu</v-icon>
                    </v-btn>
                </template>
                <v-expansion-panels accordion focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption secondary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>account_circle</v-icon>
                                Account &amp; Settings
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item @click="openSettings()">
                                    <v-list-item-title class="subtitle-2 secondary--text d-flex align-center">
                                        <v-icon small class="mr-1">settings</v-icon>
                                        User Settings
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="logout()">
                                    <v-list-item-title class="subtitle-2 secondary--text d-flex align-center">
                                        <v-icon class="mr-1" small>exit_to_app</v-icon>
                                        Logout
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption secondary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>desktop_windows</v-icon>
                                Running Applications
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list-item-group color="secondary">
                                <v-tooltip bottom v-for="app in runningDeployments" :key="app.aid">
                                    <template v-slot:activator="{ on }">
                                        <v-list-item
                                            v-on="on"
                                            :to="{ name: 'app-open', params: { oid: app.oid, sid: app.sid, iid: app.iid, snid: app.snid, aid: app.aid } }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <div class="subtitle-2 secondary--text d-flex justify-start align-center">
                                                        <img width="20" height="20" :src="appIcon(app)" />
                                                        <span class="ml-1">{{ app.name }}</span>
                                                    </div>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <div class="d-flex flex-column caption">
                                        <span>
                                            Space:
                                            <span class="font-weight-bold text-uppercase">{{ app.space_long_id }}</span>
                                        </span>
                                        <span>
                                            Instance:
                                            <span class="font-weight-bold text-uppercase">{{ app.instance_long_id }}</span>
                                        </span>
                                    </div>
                                </v-tooltip>
                            </v-list-item-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption secondary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>mdi-lifebuoy</v-icon>
                                Help
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item @click="openDocs()">
                                    <v-list-item-action>
                                        <v-icon small>help_outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Documentation</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { enumsData } from '@/mixins/enums'
import { intercomMethods } from '@/mixins/intercom'
import { calculateDateDifferenceInDays } from '../utils'
const BreadcrumbsLargeScreen = () => import('./BreadcrumbsLargeScreen')
const BreadcrumbsSmallScreen = () => import('./BreadcrumbsSmallScreen')
const StopApplication = () => import('@/modules/application/components/TheApplicationStopDialog')

export default {
    components: {
        BreadcrumbsLargeScreen,
        BreadcrumbsSmallScreen,
        StopApplication
    },
    mixins: [appTypeAndImageLink, enumsData, intercomMethods],
    data() {
        return {
            customBaseURL: window.localStorage.getItem('nvBaseUrl')
        }
    },
    methods: {
        openDocs: function () {
            window.open('https://docs.nuvolos.cloud', '_blank')
        },
        openSettings: function () {
            this.$router.push({ name: 'user-profile' })
        },
        openResourceAndUsage: function () {
            this.$router.push({ name: 'user-resources' })
        },
        openMonitoring: function () {
            this.$router.push({ name: 'reporting-dashboard' })
        },
        openSysadmin: function () {
            this.$router.push({ name: 'admin-overview' })
        },
        openTasks: function () {
            this.$router.push({ name: 'user-tasks' })
        },
        openRequests: function () {
            this.$router.push({ name: 'user-requests' })
        },
        reloadPage() {
            location.reload()
        },
        logout() {
            this.$auth.logout()
        }
    },
    computed: {
        ...mapGetters('appStore', ['runningDeployments']),
        ...mapState(['userInfo', 'trialInfo']),
        ...mapState('userStore', ['userRequests', 'isAccountManager', 'runningTasks']),
        ...mapGetters('userStore', ['validRequests']),
        trialText() {
            if (this.trialInfo && this.trialInfo.valid) {
                return `Trial period: ${this.trialDaysLeft} days left.`
            }
            if (this.trialInfo && !this.trialInfo.valid) {
                return `Your trial is up!`
            }
            return ''
        },
        trialButtonLabel() {
            if (this.trialInfo && this.trialInfo.tier === this.trialTiers.FREE) {
                return 'Upgrade'
            } else {
                return 'Convert'
            }
        },
        trialMsgIntercom() {
            if (this.trialInfo && this.trialInfo.tier === this.trialTiers.FREE) {
                return 'Hi Nuvolos Team,\n\nI would be interested in upgrading my trial account to test the complete Nuvolos experience.'
            } else {
                return 'Hi Nuvolos Team,\n\nI would like to convert my trial account to a regular plan account.'
            }
        },
        trialDaysLeft() {
            if (this.trialInfo && this.trialInfo.end_date) {
                return calculateDateDifferenceInDays(Date.now(), this.trialInfo.end_date)
            }
            return null
        },
        displayAPIWarning() {
            return this.$appConfig.VUE_APP_ENV !== 'production' && this.customBaseURL
        },
        runningTasksError() {
            return this.runningTasks instanceof Error
        }
    },
    watch: {
        userInfo: {
            async handler(user) {
                if (user) {
                    this.$store.dispatch('userStore/fetchAccountManagerStatus')
                    this.$store.dispatch('userStore/fetchRunningUserTasks')
                }
            },
            immediate: true
        }
    }
}
</script>
