export const appTypeAndImageLink = {
    data() {
        return {
            appTypes: {
                JUPYTER: { name: 'jupyter' },
                RSTUDIO: { name: 'rstudio' },
                MATLAB: { name: 'matlab' },
                STATA: { name: 'stata' }
            },
            appDisabledReasons: ['This application requires a license', 'You need the tables feature enabled to use this app']
        }
    },
    methods: {
        appIcon(app) {
            return app.icon_url ? app.icon_url : 'https://dlcfc4rxk1sfk.cloudfront.net/nuvolos/app_logos/generic_app_icon.svg'
        }
    }
}
